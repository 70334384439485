.App {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

#brand{
  display: flex;
	color: white;
}

.navItem {
  display: flex;
  color: white;
}
