.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  bottom: 0;
}

.video-responsive iframe {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  width: 50%;
  position: absolute;
  justify-content: center;
  align-items: center;
}
