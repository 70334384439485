#unit{
	background-color: #80e5ff;
	font-family: fontVariant;
	font-size: 20px;
}

#image {
  height: 200px;
  width: 200px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center
}

img {
  max-width: 100%
}

.image {
  flex-basis: 40%
}

.text {
  font-size: 20px;
  padding-left: 20px;
}

.MyMenu {
  text-align: center;
	display: center;
}

h2 .blue-text {
	 color: blue;
 }

 h3 .blue-text {
 	 color: blue;
  }

.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
	fontSize: 12;
}

.wrapper90pct{
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
	fontSize: 12;
}

.wrapper_contacts {
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: left;
	font-size: 12;
}

body {
    font: 15px/1.5 arial, helvetica, sans-serif;
		align-items: center;
		justify-content: center;
}

.small_font {
		font-size: 10px;
}

p:first-letter {
    float: left;
    font-size: 45px;
    line-height: 1;
    font-weight: bold;
    margin-right: 9px;
}

p {
  border-bottom:1px dotted;
  margin-left: auto;
  margin-right: auto;
  text-align:left;
  width: 500px;
}

.each_person {
  border-bottom:1px dotted;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
	align-items: center;
	justify-content: center;
  width: 500px;
}

.left-side {
	text-align: left;
  backgroundColor: 'teal';
  color:'white';
  padding: 10;
  margin-Left: 200;
  width:400;
}

.my_center {
	text-align: left;
	align-items: center;
	justify-content: center;
  color:'white';
  padding: 10;
	margin-left: auto;
	margin-right: auto;
	width: auto;
  max-width: 400px;

}

.my_inner_center {
	width: auto;
	display: flex;
}


.ctr_outer {
	align-items: center;
  color:'white';
  padding: 10;
	max-width: 400px;
	width: auto;
	min-width: 100px;
}

.ctr_video {
	align-items: center;
  color:'white';
  padding: 10;
	max-width: 600px;
	width: auto;
	min-width: 100px;
	max-height: 300px;
}

.ctr_inner {
	text-align: left;
  color:'white';
  width: auto;
	resize: horizontal;
}


.my_div {
  resize: horizontal;
  overflow: auto;
}



.right-side {
  float: right;
}
